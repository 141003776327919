<script lang="ts">
    import Dropzone from 'dropzone';
    import 'dropzone/dist/dropzone.css';
    import { Spinner } from '$components/basic';
    import { toastDanger } from '$lib/utils';
    import { onMount } from 'svelte';
    import { twMerge } from 'tailwind-merge';

    export let url: string;
    export let autoSubmit = false;
    export let maxFiles = 1;
    export let uploadFiles: File[] = [];
    export let msgFileSizeError = '파일 용량이 초과되었습니다.';
    export let msgTypeError = '지원하지 않는 파일 형식입니다.';
    export let testMode = false;

    let isUploading = false;
    let dropzone: Dropzone;
    let dropzoneEl: HTMLDivElement;
    let previewTemplateEl: HTMLDivElement;
    let defaultClass = twMerge('dropzone', $$props.class);

    export function submitHandler() {
        if (dropzone && dropzone.files.length > 0) {
            dropzone.processQueue(); // dropzone의 파일 대기열 파일들을 서버로 전송
        }
    }

    async function initializeDropzone() {
        dropzone = new Dropzone(dropzoneEl, getDropzoneOptions());
        setDropzoneEventListeners();
    }

    function getDropzoneOptions() {
        return {
            url,
            autoProcessQueue: autoSubmit,
            maxFiles,
            method: 'post',
            uploadMultiple: false,
            addRemoveLinks: false,
            parallelUploads: 99,
            previewTemplate: previewTemplateEl.innerHTML,
            dictMaxFilesExceeded: '최대 파일 개수를 초과하였습니다.',
            ...$$restProps,
        };
    }

    function setDropzoneEventListeners() {
        dropzone.on('addedfile', handleFileAdded);
        dropzone.on('error', handlefileError);
        dropzone.on('uploadprogress', handleUploadProgress);
        dropzone.on('sending', handleFileSending);
        dropzone.on('removedfile', handleFileRemoved);
        // dropzone.on('complete', handlefileCompleted);
        // dropzone.on('success', handleFileSuccess);
    }

    function handleFileAdded(file: Dropzone.DropzoneFile) {
        if (dropzone.files.length <= maxFiles) {
            uploadFiles = dropzone.files as File[];
        } else {
            dropzone.removeFile(file);
        }
    }

    function handlefileError(file: Dropzone.DropzoneFile, response: string | Error) {
        if (response && typeof response === 'string') {
            if (response.includes('File is too big')) {
                dropzone.removeFile(file);
                toastDanger(msgFileSizeError);
            } else if (response.includes("You can't upload files of this type")) {
                dropzone.removeFile(file);
                toastDanger(msgTypeError);
            } else {
                toastDanger(response);
            }
        } // else if (response && response.message) {
        // 서버에서 제공된 JSON 오류 메시지
        // }
    }

    function handleFileRemoved(file: Dropzone.DropzoneFile) {
        uploadFiles = uploadFiles.filter(f => f.name !== file.name);
    }

    // function handlefileCompleted(file) {
    //     console.log('complete');
    // }
    // function handleFileSuccess(file, response) {
    //     console.log('success');
    // }

    function handleUploadProgress(file: Dropzone.DropzoneFile, progress: number, bytesSent: number) {
        isUploading = progress < 100 && progress > 0;

        if (file.previewElement) {
            const spinner = file.previewElement.querySelector('#uploading') as HTMLElement;
            const svgIcon = file.previewElement.querySelector('#file-icon') as HTMLElement;

            if (!spinner || !svgIcon) return;

            if (isUploading) {
                spinner.style.display = 'flex';
                svgIcon.style.display = 'none';
            } else {
                spinner.style.display = 'none';
                svgIcon.style.display = 'flex';
            }
        }
    }

    function handleFileSending(file: Dropzone.DropzoneFile, xhr: XMLHttpRequest, formData: FormData) {
        if (testMode) {
            // Spinner 테스트를 위한 지연 로직
            const steps = 8;
            const totalMs = 5000;
            const timeouts: NodeJS.Timeout[] = [];

            for (let i = 0; i < steps; i++) {
                timeouts.push(
                    setTimeout(
                        () => {
                            dropzone.emit('uploadprogress', file, (100 / (steps - 1)) * i, (file.size / (steps - 1)) * i);
                            if (i === steps - 1) {
                                file.status = 'success';

                                dropzone.emit('success', file, 'success');
                                dropzone.emit('complete', file);
                                dropzone.processQueue();

                                if (dropzone.getFilesWithStatus('success').length == 4) {
                                    dropzone.disable();
                                }
                            }
                        },
                        (totalMs / steps) * i,
                    ),
                );
            }
        } else {
            // 실제 서버로 파일 전송시 실행되는 로직
        }
    }

    onMount(async () => {
        try {
            await initializeDropzone();
        } catch (e) {
            console.error(e);
        }
    });
</script>

<div bind:this={dropzoneEl} class={defaultClass}>
    <slot>
        <div class="dz-message needsclick">
            <span class="text flex items-center justify-center">
                <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.8 27.7C4.8 27.2757 4.96857 26.8687 5.26863 26.5686C5.56869 26.2686 5.97565 26.1 6.4 26.1H25.6C26.0243 26.1 26.4313 26.2686 26.7314 26.5686C27.0314 26.8687 27.2 27.2757 27.2 27.7C27.2 28.1243 27.0314 28.5313 26.7314 28.8314C26.4313 29.1314 26.0243 29.3 25.6 29.3H6.4C5.97565 29.3 5.56869 29.1314 5.26863 28.8314C4.96857 28.5313 4.8 28.1243 4.8 27.7ZM10.0688 15.3688C10.3688 15.0688 10.7757 14.9003 11.2 14.9003C11.6243 14.9003 12.0312 15.0688 12.3312 15.3688L14.4 17.4376V5.3C14.4 4.87565 14.5686 4.46868 14.8686 4.16863C15.1687 3.86857 15.5757 3.7 16 3.7C16.4243 3.7 16.8313 3.86857 17.1314 4.16863C17.4314 4.46868 17.6 4.87565 17.6 5.3V17.4376L19.6688 15.3688C19.8164 15.216 19.9929 15.0941 20.1882 15.0102C20.3834 14.9264 20.5933 14.8822 20.8058 14.8804C21.0182 14.8785 21.2289 14.919 21.4255 14.9995C21.6222 15.0799 21.8008 15.1987 21.951 15.349C22.1013 15.4992 22.2201 15.6778 22.3005 15.8745C22.381 16.0711 22.4214 16.2818 22.4196 16.4942C22.4178 16.7067 22.3736 16.9166 22.2898 17.1118C22.2059 17.3071 22.084 17.4836 21.9312 17.6312L17.1312 22.4312C16.8312 22.7311 16.4243 22.8997 16 22.8997C15.5757 22.8997 15.1688 22.7311 14.8688 22.4312L10.0688 17.6312C9.76885 17.3312 9.60034 16.9243 9.60034 16.5C9.60034 16.0757 9.76885 15.6688 10.0688 15.3688Z"
                        fill="#606469"
                    />
                </svg>
            </span>
            <div>파일을 업로드하려면</div>
            <div>클릭하거나 Drag & Drop 하세요</div>
        </div>
    </slot>
</div>
<div bind:this={previewTemplateEl} class="hidden">
    <div class="dz-preview dz-file-preview">
        <slot name="preview" class="gap-4">
            <div class="flex w-full justify-between gap-4">
                <div class="dz-details !static m-0 flex h-fit w-full !min-w-0 items-center justify-start !p-0 !pr-8 !opacity-100">
                    <!-- 업로드 중일 때 보여줄 아이콘 -->
                    <div id="uploading" class="hidden">
                        <Spinner class="h-8 w-8" />
                    </div>
                    <!-- 업로드 완료 전,후 보여줄 아이콘 -->
                    <svg id="file-icon" class="flex shrink-0" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.39999 6.4C6.39999 5.5513 6.73714 4.73737 7.33725 4.13726C7.93737 3.53714 8.7513 3.2 9.59999 3.2H16.9376C17.7862 3.20018 18.6 3.53744 19.2 4.1376L24.6624 9.6C25.2626 10.2 25.5998 11.0138 25.6 11.8624V25.6C25.6 26.4487 25.2628 27.2626 24.6627 27.8627C24.0626 28.4629 23.2487 28.8 22.4 28.8H9.59999C8.7513 28.8 7.93737 28.4629 7.33725 27.8627C6.73714 27.2626 6.39999 26.4487 6.39999 25.6V6.4ZM9.59999 16C9.59999 15.5757 9.76856 15.1687 10.0686 14.8686C10.3687 14.5686 10.7756 14.4 11.2 14.4H20.8C21.2243 14.4 21.6313 14.5686 21.9314 14.8686C22.2314 15.1687 22.4 15.5757 22.4 16C22.4 16.4243 22.2314 16.8313 21.9314 17.1314C21.6313 17.4314 21.2243 17.6 20.8 17.6H11.2C10.7756 17.6 10.3687 17.4314 10.0686 17.1314C9.76856 16.8313 9.59999 16.4243 9.59999 16ZM11.2 20.8C10.7756 20.8 10.3687 20.9686 10.0686 21.2686C9.76856 21.5687 9.59999 21.9757 9.59999 22.4C9.59999 22.8243 9.76856 23.2313 10.0686 23.5314C10.3687 23.8314 10.7756 24 11.2 24H20.8C21.2243 24 21.6313 23.8314 21.9314 23.5314C22.2314 23.2313 22.4 22.8243 22.4 22.4C22.4 21.9757 22.2314 21.5687 21.9314 21.2686C21.6313 20.9686 21.2243 20.8 20.8 20.8H11.2Z"
                            fill="#606469"
                        />
                    </svg>
                    <div class="dz-filename flex w-full text-black">
                        <span class="line-clamp-1 w-full text-sm font-medium" data-dz-name />
                    </div>
                </div>
                <div class="flex items-center gap-4">
                    <div class="dz-size mb-0 flex shrink-0" data-dz-size />
                    <div class="dz-remove static flex h-fit shrink-0 items-center" data-dz-remove>
                        <svg class="flex shrink-0 !cursor-pointer" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 4.5L13.5 13.5M4.5 13.5L13.5 4.5L4.5 13.5Z" stroke="#D1D5DB" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                </div>
            </div>
        </slot>
        <div class="dz-error-message"><span data-dz-errormessage /></div>
        <div class="dz-max-files-reached" />
    </div>
</div>

<!--
@component
## Features
[Go to Dropzone](https://github.com/dropzone/website)

## Props
  @prop {string} url - 파일 전송 url
  @prop {boolean} autoSubmit - 자동 전송 여부
  @prop {number} maxFiles - 최대 파일 개수
  @prop {string} acceptedFiles - 허용할 파일 확장자
  @prop {number} maxFilesize - 최대 파일 용량 MB 단위

## Event
- `submitHandler` - 파일 전송 이벤트

## Example
```js 
  <script>
      import { Button } from '$components/basic';
      import { Dropzone } from '$components/uploader';

      let dropzone;
  </script>

  // 기본 사용, 파일 1개, 자동 전송 X
  <div class="flex flex-col gap-3">
      <Dropzone bind:this={dropzone} url={'/'} />
      <Button on:click={() => dropzone.submitHandler()}>Submit</Button>
  </div>

  // 자동전송, 파일 5개, slot 사용
  <div class="flex flex-col gap-3">
      <Dropzone url={'/'} maxFiles="5" autoSubmit>
          <div class="dz-message needsclick">
              <span class="text flex items-center justify-center animate-bounce">
                  <드랍존 아이콘>
              </span>
              <div>클릭하여 업로드하거나 드래그해 놓으세요.</div>
              <div>DOC, DOCX 지원 (PDF 불가, 최대 30KB)</div>
          </div>
          <div slot="preview" class="flex w-full justify-between">
              <div class="dz-details flex justify-start !pr-8 !opacity-100 !static items-center !min-w-0 w-full h-fit !p-0 m-0">
                  // 업로드 중일 때 보여줄 아이콘
                  <div id="uploading" class="absolute top-[calc(50%-1rem)] left-[calc(50%-1rem)]">
                      <Spinner color="red" class="w-8 h-8" />
                  </div>
                  // 업로드 완료 전,후 보여줄 아이콘
                  <파일아이콘>     
                  <div class="dz-filename flex text-black w-full">
                      <span class="text-sm font-medium w-full line-clamp-1" data-dz-name />
                  </div>
              </div>
              <div class="flex gap-4 items-center">
                  <div class="dz-size flex mb-0 shrink-0" data-dz-size />
                  <div class="dz-remove flex static h-fit items-center shrink-0 " data-dz-remove>
                      <삭제아이콘>
                  </div>            
              </div>
          </div>
      </Dropzone>
  </div>
  ```
-->

<style lang="postcss">
    :global(.dropzone) {
        @apply flex h-full !min-h-0 w-full flex-col items-center justify-center rounded-lg border border-dashed border-gray-200 p-4;
    }

    :global(.dz-preview) {
        @apply !my-[14px] flex !min-h-0 w-full;
    }

    :global(.dz-filename *) {
        @apply !whitespace-normal !bg-transparent text-start;
    }

    :global(.dz-remove *) {
        @apply pointer-events-auto !cursor-pointer;
    }

    :global(.dz-success-mark) {
        @apply hidden;
    }

    :global(.dz-error-mark) {
        @apply hidden;
    }

    :global(.dz-details) {
        svg {
            @apply !shrink-0;
        }
    }

    :global(.dz-max-files-reached) {
        pointer-events: none;
        cursor: default;
    }
</style>
