<script lang="ts">
    import { goto } from '$app/navigation';
    import { sortBy, isEqual, isEmpty } from 'lodash-es';
    import { Radio, SvelteSelect, Input, Button } from '$components/basic';
    import ImageMachineLearning from '$lib/assets/illustration/machine-learning.png';
    import Dropzone from '$components/uploader/Dropzone.svelte';
    import { onMount } from 'svelte';
    import { AiReviewService } from '$lib/api';
    import { page } from '$app/stores';
    import { browser } from '$app/environment';

    let lang = 'ko';
    let allContractTypeItems: any[] = [];
    let contractTypeItems: any[] = [];
    let contractType: any;
    let contractPartyTypeItems: any[] = [];
    let contractPartyType: any = null;
    let contractPartyName = '';
    let otherContractPartyName = '';
    let uploadFiles: File[] = [];
    let loadingAnalyze = false;
    $: lang, changedLang();
    $: contractType, chnagedContractType();
    $: otherContractPartyType = contractPartyTypeItems.find(val => !isEqual(val, contractPartyType)) || {};
    $: validate = (() => {
        if (browser) {
            if ($page.url.searchParams.get('novalidate') === '1' && lang && contractType && contractPartyType && otherContractPartyType && !isEmpty(uploadFiles)) {
                return true;
            } else if (lang && contractType && contractPartyType && otherContractPartyType && contractPartyName && otherContractPartyName && !isEmpty(uploadFiles)) {
                return true;
            }
        }
        return false;
    })();

    onMount(async () => {
        const res = await AiReviewService.getContractTypes();
        allContractTypeItems = res.data.data;
        changedLang();
    });

    function changedLang() {
        contractTypeItems = sortBy(
            allContractTypeItems.filter(val => val.language === lang),
            'displayOrder',
        ).map(data => ({ data, value: data.name, label: data.name }));
        if (contractTypeItems?.length > 0) {
            contractType = contractTypeItems[0];
        }
    }

    function chnagedContractType() {
        if (contractType) {
            contractPartyTypeItems = (contractType.data.partyStatuses as string[]).map(partyStatus => ({ value: partyStatus, label: partyStatus }));
            contractPartyType = null;
        }
    }

    async function startAnalyze() {
        loadingAnalyze = true;
        let predictData;
        try {
            const res = await AiReviewService.postPredict({
                language: lang,
                contractType: contractType.data.type,
                file: uploadFiles[0],
            });
            predictData = res.data.data;

            goto('./main', {
                state: {
                    lang: { label: getLangLabel(lang), value: lang },
                    contractType: contractType,
                    contractParty: { type: contractPartyType.value, value: contractPartyName },
                    otherContractParty: { type: otherContractPartyType.value, value: otherContractPartyName },
                    file: uploadFiles[0],
                    predictData: predictData,
                },
            });
        } catch (err: any) {
            console.error(err);
            loadingAnalyze = false;
        }
    }

    function getLangLabel(lang: string) {
        switch (lang) {
            case 'ko':
                return '한국어';
            case 'en':
                return 'English';
            default:
                return '';
        }
    }
</script>

<div class="grid h-auto min-h-full grid-rows-[auto_1fr_auto]">
    <div class="relative flex flex-col items-start justify-start gap-0">
        <div class="relative flex h-12 shrink-0 flex-row items-center justify-between self-stretch bg-gray-100 pb-[12.5px] pl-3 pr-6 pt-[12.5px]">
            <div class="relative flex items-center justify-center text-center text-base font-extrabold text-gray-900">BHSN AI Contract Review</div>
            <div class="relative flex w-[1050px] items-center justify-end text-right text-xs font-normal text-gray-500">
                BHSN AI 리뷰는 계약 업무 수행과 관련하여 사용자를 보조하는 기능에 불과하며, 100% 수준의 정확성 및 신뢰도를 담보하지 <br />
                않습니다. 계약서 검토 및 관리와 관련하여 전문적인 법률서비스가 필요하신 경우라면 변호사로부터 자문을 받으실 것을 권장 드립니다.
            </div>
        </div>
        <div class="relative h-px shrink-0 self-stretch bg-gray-200" />
    </div>
    <div class="grid grid-flow-col grid-cols-[1fr_1fr]">
        <div class="bg-gray-200">
            <!-- 좌측 영역 -->
            <div class="relative flex shrink-0 flex-col items-center justify-start gap-8 self-stretch bg-gray-200 pb-10 pl-20 pr-20 pt-10">
                <div class="relative flex shrink-0 flex-col items-start justify-start gap-4 self-stretch">
                    <div class="relative text-left text-sm font-medium text-gray-500">계약서 언어</div>
                    <div class="relative flex shrink-0 flex-row items-start justify-start gap-8">
                        <Radio value="ko" bind:group={lang}>한국어</Radio>
                        <Radio value="en" bind:group={lang}>English</Radio>
                    </div>
                </div>

                <div class="relative flex shrink-0 flex-col items-start justify-start gap-2 self-stretch">
                    <div class="relative self-stretch text-left text-sm font-medium text-gray-500">계약서 종류</div>
                    <SvelteSelect items={contractTypeItems} bind:value={contractType} clearable={false} searchable={false} showChevron />
                </div>

                <div class="relative flex shrink-0 flex-col items-start justify-start gap-2 self-stretch">
                    <div class="relative self-stretch text-left text-sm font-medium text-gray-500">당사자</div>
                    <Input placeholder="당사자 이름을 입력해주세요." bind:value={contractPartyName} />
                </div>

                <div class="relative flex shrink-0 flex-col items-start justify-start gap-2 self-stretch">
                    <div class="relative self-stretch text-left text-sm font-medium text-gray-500">당사자 지위</div>
                    <SvelteSelect items={contractPartyTypeItems} bind:value={contractPartyType} clearable={false} searchable={false} showChevron />
                </div>

                <div class="relative flex shrink-0 flex-col items-start justify-start gap-2 self-stretch">
                    <div class="relative self-stretch text-left text-sm font-medium text-gray-500">계약 상대방</div>
                    <Input placeholder="계약 상대방을 입력해주세요." bind:value={otherContractPartyName} />
                </div>

                <div class="relative flex shrink-0 flex-col items-start justify-start gap-2 self-stretch">
                    <div class="relative self-stretch text-left text-sm font-medium text-gray-500">분석할 계약서 파일</div>
                    <Dropzone class="box-border bg-white" url={'/'} acceptedFiles=".doc,.docx" msgTypeError={'지원하지 않는 파일 양식입니다.<br />DOC, DOCX 지원 (PDF 불가)'} bind:uploadFiles>
                        <div class="dz-message needsclick">
                            <div class="inline-flex h-[85px] w-[232px] flex-col items-center justify-start gap-[7px] text-sm text-gray-500">
                                <svg class="relative shrink-0 overflow-visible rounded" style="" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M4.80005 27.7C4.80005 27.2756 4.96862 26.8686 5.26868 26.5686C5.56874 26.2685 5.9757 26.1 6.40005 26.1H25.6C26.0244 26.1 26.4314 26.2685 26.7314 26.5686C27.0315 26.8686 27.2 27.2756 27.2 27.7C27.2 28.1243 27.0315 28.5313 26.7314 28.8313C26.4314 29.1314 26.0244 29.3 25.6 29.3H6.40005C5.9757 29.3 5.56874 29.1314 5.26868 28.8313C4.96862 28.5313 4.80005 28.1243 4.80005 27.7ZM10.0688 15.3688C10.3689 15.0688 10.7758 14.9003 11.2 14.9003C11.6243 14.9003 12.0312 15.0688 12.3312 15.3688L14.4 17.4376V5.29995C14.4 4.8756 14.5686 4.46864 14.8687 4.16858C15.1687 3.86852 15.5757 3.69995 16 3.69995C16.4244 3.69995 16.8314 3.86852 17.1314 4.16858C17.4315 4.46864 17.6 4.8756 17.6 5.29995V17.4376L19.6688 15.3688C19.8164 15.2159 19.993 15.094 20.1882 15.0102C20.3834 14.9263 20.5934 14.8822 20.8058 14.8804C21.0183 14.8785 21.2289 14.919 21.4256 14.9994C21.6222 15.0799 21.8009 15.1987 21.9511 15.3489C22.1013 15.4991 22.2201 15.6778 22.3006 15.8744C22.381 16.0711 22.4215 16.2817 22.4197 16.4942C22.4178 16.7066 22.3737 16.9166 22.2898 17.1118C22.206 17.307 22.0841 17.4836 21.9312 17.6312L17.1312 22.4312C16.8312 22.7311 16.4243 22.8996 16 22.8996C15.5758 22.8996 15.1689 22.7311 14.8688 22.4312L10.0688 17.6312C9.7689 17.3311 9.60039 16.9242 9.60039 16.5C9.60039 16.0757 9.7689 15.6688 10.0688 15.3688Z"
                                        fill="#606469"
                                    />
                                </svg>
                                <div class="text-center"><strong>클릭</strong>하여 업로드하거나 <strong>드래그</strong>해 놓으세요.</div>
                                <div class="text-center text-xs font-normal">DOC, DOCX 지원 (PDF 불가)</div>
                            </div>
                        </div>
                    </Dropzone>
                </div>
            </div>
        </div>
        <div class="flex-1 bg-white">
            <!-- 우측 영역 -->
            <div class="relative flex flex-1 flex-col items-center justify-start self-stretch pt-40">
                <div class="flex w-96 flex-col gap-12">
                    <img class="h-[289.2px]" src={ImageMachineLearning} alt="머신러닝 로고" />
                    <div class="relative w-96 text-left text-sm font-medium text-gray-500">
                        계약서를 업로드하면 AI가 핵심 용어를 추출하고 위험 요소를 식별하여 최적의 조항을 제안합니다. 지금 시작해보세요!
                    </div>
                    <Button color="dark" loading={loadingAnalyze} on:click={startAnalyze} disabled={!(validate && !loadingAnalyze)}>AI 분석 시작</Button>
                </div>
            </div>
        </div>
    </div>
</div>
