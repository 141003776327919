<script context="module" lang="ts">
    // this part is shared between Radio and Checkbox
    import { twMerge } from 'tailwind-merge';

    const colorClasses: Record<FormColorType, string> = {
        primary: 'text-primary-600 checked:bg-gray-900 disabled:checked:bg-gray-300 focus:ring-gray-100 dark:focus:ring-gray-100',
        secondary: 'text-secondary-600 focus:ring-secondary-500 dark:focus:ring-secondary-600',
        red: 'text-red-600 focus:ring-red-500 dark:focus:ring-red-600',
        green: 'text-green-600 focus:ring-green-500 dark:focus:ring-green-600',
        purple: 'text-purple-600 focus:ring-purple-500 dark:focus:ring-purple-600',
        teal: 'text-teal-600 focus:ring-teal-500 dark:focus:ring-teal-600',
        yellow: 'text-yellow-400 focus:ring-yellow-500 dark:focus:ring-yellow-600',
        orange: 'text-orange-500 focus:ring-orange-500 dark:focus:ring-orange-600',
        blue: 'text-blue-600 focus:ring-blue-500 dark:focus:ring-blue-600',
    };

    const labelClass = (inline: boolean, extraClass: string) => twMerge(inline ? 'inline-flex' : 'flex', 'items-center', extraClass);
    export let spacing = 'mr-2';

    const inputClass = (custom: boolean, color: FormColorType, rounded: boolean, tinted: boolean, extraClass: string) =>
        twMerge(
            'w-4 h-4 bg-gray-50 border-gray-200 dark:ring-offset-gray-800 focus:ring',
            'cursor-pointer disabled:cursor-not-allowed appearance-none',
            spacing,
            tinted ? 'dark:bg-gray-600 dark:border-gray-500' : 'dark:bg-gray-700 dark:border-gray-600',
            custom && 'sr-only peer',
            rounded && 'rounded',
            colorClasses[color],
            extraClass,
        );
</script>

<script lang="ts">
    import { getContext } from 'svelte';
    import type { FormColorType } from '../types';
    import Label from './Label.svelte';

    export let color: FormColorType = 'primary';
    export let custom = false;
    export let inline = false;
    export let group: number | string = '';
    export let value: number | string = '';

    // tinted if put in component having its own background
    let background: boolean = getContext('background');

    const labelDefaultClass = twMerge('select-none font-normal', $$props.disabled ? 'cursor-not-allowed' : 'cursor-pointer');
</script>

<Label class={labelClass(inline, twMerge(labelDefaultClass, group === value && 'font-bold', $$props.class))} show={$$slots.default}>
    <input
        type="radio"
        bind:group
        on:blur
        on:change
        on:click
        on:focus
        on:keydown
        on:keypress
        on:keyup
        on:mouseenter
        on:mouseleave
        on:mouseover
        on:paste
        {value}
        {...$$restProps}
        class={inputClass(custom, color, false, background, $$slots.default || $$props.class)}
    />
    <slot />
</Label>

<!--
  @component
  ## Feature
  [Go to Radio](https://flowbite-svelte.com/docs/forms/radio)
  - Setup
  - Radio examples
  - Disabled state
  - Alternative syntax
  - Radio with a link
  - Helper text
  - Bordered
  - Radio list group
  - Horizontal list group
  - Radio in dropdown
  - Inline layout
  - Colors
  - Advanced layout
  ## Props
  @prop color: FormColorType = 'primary';
  @prop custom: boolean = false;
  @prop inline: boolean = false;
  @prop group: number | string = '';
  @prop value: number | string = '';
  ## Event
  - on:blur
  - on:change
  - on:click
  - on:focus
  - on:keydown
  - on:keypress
  - on:keyup
  - on:mouseenter
  - on:mouseleave
  - on:mouseover
  - on:paste
  ## Example
  ```
  <script>
    import { Radio } from '$component/basic'
  </script>

  <Radio name="example">Default radio</Radio>
  <Radio name="example">Checked state</Radio>
  ```
-->
