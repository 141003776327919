<script lang="ts">
    import { Badge } from '$components/basic';
    import Select from 'svelte-select';
    import { tailwindConfig } from '$lib/utils';
    import { twMerge } from 'tailwind-merge';
    import { isEqual } from 'lodash-es';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let items: any[] = [];
    export let value: any = null;
    export let multiple = false;
    export let label = 'label';

    // 아래는 checkbox와 같이 filterSelectedItems 이 false 일 때 사용할 수 있는 부분을 위해 필요한 변수와 함수들
    // https://svelte-select-examples.vercel.app/examples/advanced/multi-item-checkboxes
    let checked = Array.isArray(value) ? value.map(({ value }) => value) : [];

    $: if (!value || value?.length === 0) {
        checked = [];
    }
    $: isControllable = $$slots.item && $$props.filterSelectedItems === false && multiple;
    $: checked, computeValue();

    function computeValue() {
        if (!isControllable) return;
        value = checked.map(c => items.find(i => i.value === c));
    }

    function handleChange(e) {
        if (!isControllable) return;
        checked.includes(e.detail.value) ? (checked = checked.filter(i => i != e.detail.value)) : (checked = [...checked, e.detail.value]);
    }
    function handleClear(e) {
        if (Array.isArray(e.detail)) {
            checked = [];
            dispatch('change', { detail: [] });
        } else {
            value = null;
            dispatch('change', { detail: null });
        }
    }
</script>

<Select
    bind:items
    bind:value
    bind:multiple
    placeholder="선택해주세요"
    class="svelte-select-container min-h-12 bg-gray-50 font-normal text-gray-500"
    --font-size="14px"
    --height="48px"
    --item-height="37px"
    --list-max-height="269px"
    --border-focused={`1px solid ${tailwindConfig.theme.colors['gray-500']}`}
    --item-padding="0"
    --item-is-active-bg={tailwindConfig.theme.colors['gray-300']}
    --item-is-active-color={tailwindConfig.theme.colors['gray-900']}
    --item-hover-bg={tailwindConfig.theme.colors['gray-200']}
    --item-hover-color={tailwindConfig.theme.colors['gray-900']}
    --selected-item-color={tailwindConfig.theme.colors['gray-900']}
    --list-shadow="0px 0px 3px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px -1px rgba(0, 0, 0, 0.10)"
    --list-border-radius="4px"
    --list-border={`1px solid ${tailwindConfig.theme.colors['gray-200']}`}
    --placeholder-color={tailwindConfig.theme.colors['gray-400']}
    --input-color={tailwindConfig.theme.colors['gray-900']}
    --disabled-color={tailwindConfig.theme.colors['gray-400']}
    --list-background={tailwindConfig.theme.colors['gray-50']}
    --disabled-background={tailwindConfig.theme.colors['gray-200']}
    --background={tailwindConfig.theme.colors['gray-50']}
    --border={`1px solid ${tailwindConfig.theme.colors['gray-200']}`}
    --border-radius="8px"
    --border-hover={`1px solid ${tailwindConfig.theme.colors['gray-200']}`}
    --multi-item-bg={tailwindConfig.theme.colors['gray-50']}
    --multi-item-outline={'none'}
    --multi-item-border-radius={0}
    --multi-item-padding={0}
    --multi-item-gap={0}
    --multi-item-height={1}
    --multi-select-input-margin={0}
    --clear-select-margin={'0 0 0 8px'}
    --clear-select-width="18px"
    --item-first-border-radius="8px"
    inputAttributes={{ class: 'disabled:!cursor-not-allowed hover:!cursor-pointer' }}
    on:select={handleChange}
    on:clear={handleClear}
    on:select
    on:clear
    on:blur
    on:change
    on:focus
    on:keydown
    {...$$restProps}
    on:*
>
    <div class="cursor-pointer rounded-lg p-2 text-sm" class:font-semibold={value === item} slot="item" let:item let:index>
        <slot name="item" {item} {index} checked={value?.find(val => isEqual(val, item))}>{item.label}</slot>
    </div>

    <div slot="selection" let:selection let:index>
        {#if multiple}
            <slot name="selection" {selection} {index}>
                {#key `${selection.value}-${index}`}
                    <Badge
                        color="gray"
                        dismissable={true}
                        on:dismiss={() => {
                            value = value.filter(item => item !== selection);
                        }}
                    >
                        {selection[label]}
                    </Badge>
                {/key}
            </slot>
        {:else}
            <slot name="selection" {selection} {index}>
                {selection[label]}
            </slot>
        {/if}
    </div>

    <svelte:fragment slot="multi-clear-icon">
        <slot name="multi-clear-icon" />
    </svelte:fragment>

    <div slot="chevron-icon" class="{listOpen ? '' : 'rotate-180'} transition-transform" let:listOpen>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 12L9 6L3 12" stroke="#D1D5DB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>

    <div slot="clear-icon" class="cursor-pointer">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 4.5L13.5 13.5M4.5 13.5L13.5 4.5L4.5 13.5Z" stroke="#D1D5DB" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>

    <slot name="empty" slot="empty">
        <div class={twMerge('!m-0', 'p-2', $$props.listAutoWidth === false && 'w-[max-content]')}>더이상 선택가능한 항목이 없습니다</div>
    </slot>
</Select>

<!--
  @component
  ## Feature
  [Go to SvelteSelect](https://github.com/rob-balfre/svelte-select)
  - Setup
  - SvelteSelect examples
  - github
  - Examples
  ## Props
  @prop items: any[] = []
  @prop value: any = null
  @prop placeholder: string = '선택해주세요'
  @prop disabled: boolean = false

  ## Example
  ```
  <script>
    import { SveteSelect } from '$components/basic

    let value = null
  </script>

    <SveteSelect
        items={[
        { label: '선택1', value: 1 },
        { label: '선택2', value: 2 },
        { label: '선택3', value: 3 },
        ]}
        {value}
        placeholder="선택해주세요"
        disabled> 라벨 </SveteSelect>
  ```
-->

<style lang="postcss">
    /* :global(.svelte-select-container.focused) {
        @apply !border-gray-500;
    } */
    :global(.svelte-select-container) {
        @apply !border-gray-200;
    }
    :global(.svelte-select-container .list-item) {
        @apply m-1;
    }
    :global(.svelte-select-container .item) {
        @apply rounded-lg;
        @apply !text-gray-900;
    }
    :global(.svelte-select-container .item.active) {
        @apply font-semibold;
    }
    :global(.svelte-select-container .item:hover) {
        @apply font-normal;
    }
    :global(.svelte-select-container.disabled) {
        @apply cursor-not-allowed;
        @apply !bg-gray-100;
    }

    :global(.svelte-select-container .value-container) {
        @apply leading-none;
        gap: var(--value-container-gap, 0.5rem) !important;
        flex-wrap: var(--value-container-flex-wrap, wrap) !important;
    }

    :global(.svelte-select-container .indicators) {
        @apply h-max;
        @apply pr-4;
        /* chevron 가운데 정렬 */
        align-self: center;
    }

    :global(.svelte-select-container .indicators .chevron) {
        @apply !w-4;
        @apply !ml-2;
    }
</style>
