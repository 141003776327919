<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import { twMerge, twJoin } from 'tailwind-merge';
    import CloseButton from '../utils/CloseButton.svelte';

    export let color: 'none' | 'dark' | 'red' | 'yellow' | 'green' | 'primary' | 'indigo' | 'purple' | 'pink' | 'gray' = 'primary';
    export let large = false;
    export let border = false;
    export let href: string | undefined = undefined;
    export let rounded = false;
    export let index = false;
    export let dismissable = false;

    const colors = {
        primary: 'bg-primary-100 text-primary-800 dark:bg-primary-900 dark:text-primary-300',
        blue: 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300',
        dark: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300',
        red: 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300',
        green: 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300',
        yellow: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300',
        indigo: 'bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300',
        purple: 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300',
        pink: 'bg-pink-100 text-pink-800 dark:bg-pink-900 dark:text-pink-300',
        gray: 'bg-gray-200 text-gray-900',
        ['!blue']: 'bg-blue-500 text-blue-100',
        ['!dark']: 'bg-gray-500 text-gray-100',
        ['!red']: 'bg-red-500 text-white',
        ['!green']: 'bg-green-500 text-green-100',
        ['!yellow']: 'bg-yellow-300 text-yellow-800',
        ['!indigo']: 'bg-indigo-500 text-indigo-100',
        ['!purple']: 'bg-purple-500 text-purple-100',
        ['!pink']: 'bg-pink-500 text-pink-100',
        none: '',
    };

    const borderedColors = {
        primary: 'bg-primary-100 text-primary-800 dark:bg-gray-700 dark:text-primary-400 border-primary-400',
        blue: 'bg-blue-100 text-blue-800 dark:bg-gray-700 dark:text-blue-400 border-blue-400',
        dark: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-400 border-gray-500',
        red: 'bg-red-100 text-red-800 dark:bg-gray-700 dark:text-red-400 border-red-400',
        green: 'bg-green-100 text-green-800 dark:bg-gray-700 dark:text-green-400 border-green-400',
        yellow: 'bg-yellow-100 text-yellow-800 dark:bg-gray-700 dark:text-yellow-300 border-yellow-300',
        indigo: 'bg-indigo-100 text-indigo-800 dark:bg-gray-700 dark:text-indigo-400 border-indigo-400',
        purple: 'bg-purple-100 text-purple-800 dark:bg-gray-700 dark:text-purple-400 border-purple-400',
        pink: 'bg-pink-100 text-pink-800 dark:bg-gray-700 dark:text-pink-400 border-pink-400',
        gray: 'bg-gray-200 text-gray-900 border-gray-600',
        none: '',
    };

    const hoverColors = {
        primary: 'hover:bg-primary-200',
        blue: 'hover:bg-blue-200',
        dark: 'hover:bg-gray-200',
        red: 'hover:bg-red-200',
        green: 'hover:bg-green-200',
        yellow: 'hover:bg-yellow-200',
        indigo: 'hover:bg-indigo-200',
        purple: 'hover:bg-purple-200',
        pink: 'hover:bg-pink-200',
        gray: 'hover:bg-gray-300',
        none: '',
    };

    const baseClass = 'font-medium inline-flex items-center justify-center px-2 py-0.5';

    let transition = false;

    let badgeClass: string;
    $: badgeClass = twMerge(
        baseClass,
        large ? 'text-sm' : 'text-xs',
        border ? `border ${borderedColors[color]}` : colors[color],
        href && hoverColors[color],
        rounded ? 'rounded-full' : 'rounded',
        index && 'absolute font-bold border-2 border-white dark:border-gray-900',
        index && (large ? 'w-7 h-7 -top-3 -right-3' : 'w-6 h-6 -top-2 -right-2'),
        transition && 'transition-opacity duration-300 ease-out opacity-0',
        $$props.class,
    );

    const closeBtnBaseClass = 'inline-flex items-center !p-0 !m-0 !ml-1 text-sm bg-transparent rounded-sm focus:!ring-0';

    let closeBtnClass: string;
    $: closeBtnClass = twJoin(closeBtnBaseClass, 'text-gray-600 hover:text-gray-900 hover:!bg-transparent dark:hover:text-gray-300');

    let hidden = false;
    const dispatch = createEventDispatcher();

    const handleHide = () => {
        transition = true;
        setTimeout(() => {
            hidden = true;
        }, 300);
        dispatch('dismiss', {
            message: 'The badge will be dismissed.',
        });
    };
</script>

<svelte:element this={href ? 'a' : 'span'} {href} {...$$restProps} class={badgeClass} class:hidden>
    <slot />
    {#if dismissable}
        <slot name="closeBtn" {handleHide}>
            <CloseButton {color} on:click={handleHide} size={large ? 'sm' : 'xs'} name="Remove badge" class={closeBtnClass} />
        </slot>
    {/if}
</svelte:element>

<!--
  @component
  ## Features
  [Go to Badge](https://flowbite-svelte.com/docs/components/badge)
  - Default badge
  - Large badges
  - Bordered badge
  - Pills badge
  - Badges as links
  - Badges with icon
  - Notification badge
  - Button with badge
  - Badge with icon only
  - Dismissable badges
  ## Props
  @prop color: 'dark' | 'red' | 'yellow' | 'green' | 'primary' | 'indigo' | 'purple' | 'pink' | 'gray' = 'primary';
  @prop large: boolean = false;
  @prop border: boolean = false;
  @prop href: string | undefined = undefined;
  @prop rounded: boolean = false;
  @prop index: boolean = false;
  @prop dismissable: boolean = false;
  ## Example
  ```
  <script>
    import { Badge } from '$component/basic';
  </script>
  
  <Badge>Default</Badge>
  <Badge color="dark">Dark</Badge>
  ```
-->
